<template>
  <section class="formulario mx-auto">
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mt-5">
          <v-card-title>
            <div class="d-flex justify-center flex-column">
              <span
                class="text--primary font-weight-medium text-base"
                v-html="
                  `${questionIndex + 1}) ${perguntas[questionIndex].descricao}`
                "
              ></span>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <RespostaRadioboxEscolha
              v-if="perguntas[questionIndex].tipo == 'radio'"
              :indexPergunta="questionIndex"
              :textoPergunta="perguntas[questionIndex].descricao"
              :alternativas="perguntas[questionIndex].opcoes"
              :uuidPergunta="perguntas[questionIndex].uuid"
              :codeFormulario="code"
              :categoria="perguntas[questionIndex].categoria"
              :subcategoria="perguntas[questionIndex].subcategoria"
              @atualiza-resposta-local="receberResposta"
            />
            <RespostaTextEscolha
              v-if="perguntas[questionIndex].tipo == 'text'"
              :indexPergunta="questionIndex"
              :textoPergunta="perguntas[questionIndex].descricao"
              :uuidPergunta="perguntas[questionIndex].uuid"
              :codeFormulario="code"
              :categoria="perguntas[questionIndex].categoria"
              :subcategoria="perguntas[questionIndex].subcategoria"
              @atualiza-resposta-local="receberResposta"
            />
            <RespostaCheckboxEscolha
              v-if="perguntas[questionIndex].tipo == 'checkbox'"
              :indexPergunta="questionIndex"
              :textoPergunta="perguntas[questionIndex].descricao"
              :alternativas="perguntas[questionIndex].opcoes"
              :uuidPergunta="perguntas[questionIndex].uuid"
              :codeFormulario="code"
              :limite_opcoes="perguntas[questionIndex].limite_de_opcoes"
              :categoria="perguntas[questionIndex].categoria"
              :subcategoria="perguntas[questionIndex].subcategoria"
              @atualiza-resposta-local="receberResposta"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex" flat tile>
            <v-btn
              @click="anterior()"
              :disabled="questionIndex < 1 && btnFinalizaPesquisa == false"
              :loading="loadingBtnFinalizar"
              color="default"
              text
              class="pa-2 mr-auto"
            >
              Voltar
            </v-btn>
            <v-btn
              @click="proxima(perguntas[questionIndex])"
              :disabled="
                verificarObrigatoriedade(perguntas[questionIndex]) &&
                btnFinalizaPesquisa == false
              "
              color="primary"
              class="mr-4 pa-2"
              v-if="questionIndex + 1 < perguntas.length"
            >
              Próxima
            </v-btn>
            <v-btn
              v-else
              @click="finalizarFormulario(perguntas[questionIndex])"
              :disabled="disabledBtnFinalizar"
              :loading="loadingBtnFinalizar"
              color="success"
            >
              Enviar teste
            </v-btn>
          </v-card-actions>
          <v-card-actions
            class="d-flex"
            flat
            tile
            v-if="mensagemRetornoResposta"
          >
            <span
              class="pa-2 mr-auto ml-3"
              v-html="mensagemRetornoResposta"
            ></span>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-text>
            <div class="d-flex flex-row-reverse"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isDialogFinishVisible" scrollable max-width="350px">
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mt-10"
            icon
            size="50"
          >
            <v-icon size="2rem" color="primary">
              mdi-help-circle-outline
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">Finalizar formulário</h6>
        </v-card-text>
        <v-card-text>
          Deseja realmente finalizar e enviar o formulário ?
        </v-card-text>
        <v-card-text>
          <v-btn
            class="mt-4"
            color="error"
            plain
            @click="isDialogFinishVisible = false"
          >
            Não
          </v-btn>
          <v-btn color="success" class="mt-4 ml-1" @click="enviarPesquisa()">
            Sim
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "estrutura-formulario",
  inject: ["$validator"],
  props: [],
  components: {
    RespostaRadioboxEscolha: () =>
      import("../components/RespostaRadioboxEscolha.vue"),
    RespostaTextEscolha: () => import("../components/RespostaTextEscolha.vue"),
    RespostaCheckboxEscolha: () =>
      import("../components/RespostaCheckboxEscolha.vue"),
    RespostaAberta: () => import("../components/EstruturaFormulario.vue"),
    PerfectScrollbar,
  },
  data() {
    return {
      isDialogFinishVisible: false,
      inicioQuiz: false,
      iniciandoQuiz: false,
      loadingFinalizaPesquisa: false,
      loadingBtnFinalizar: false,
      overlay: true,
      textBtn: "Iniciar formulário",
      msgText: "",
      finalizaQuiz: false,
      btnFinalizaPesquisa: false,
      tempoGasto: "",
      mensagemQuiz: "",
      respostaData: null,
      perguntaTipo: "",
      perguntas: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 200,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    enviarPesquisa() {
      this.loadingBtnFinalizar = true;
      this.componenteFormulario = false;
      this.questionIndex = 0;
      this.ultimaResposta = [];
      this.respostas = [];
      this.$store
        .dispatch("pesquisas/finalizarFormularioPesquisa")
        .then(() => {});
    },
    finalizarFormulario(pergunta) {
      // Bloqueia usuário de ir para pŕoxima pergunta se a pergunta for obrigatória e não tiver sido respondida
      this.verificarObrigatoriedade(pergunta);
      // Dispara o evento de envio da última pergunta
      if (this.respostaData !== null) {
        const data = Object.assign({}, this.respostaData);
        this.$store.dispatch("pesquisas/salvarResposta", data);
      }
      // ATIVA MODAL DE CONFIRMAÇÃO DE ENVIO
      this.isDialogFinishVisible = true;
    },
    receberResposta(data, tipo) {
      this.respostaData = data;
      this.perguntaTipo = tipo;
    },
    proxima(pergunta) {
      // Bloqueia usuário de ir para pŕoxima pergunta se a pergunta for obrigatória e não tiver sido respondida
      this.verificarObrigatoriedade(pergunta);
      // Prepara o objeto da pergunta com suas respectivas respostas para salvar no banco
      if (this.respostaData != null) {
        const data = Object.assign({}, this.respostaData);
        // Função será enviada após 3.5s
        setTimeout(() => {
          this.$store.dispatch("pesquisas/salvarResposta", data).then(() => {});
        }, "3500");
      }
      // Ir para próxima pergunta
      if (this.questionIndex < this.perguntas.length) {
        this.questionIndex++;
        this.valueProgressBar = this.respostas.length;
      }
    },
    addEstruturaPergunta(pergunta) {
      this.perguntas.push({
        uuid: pergunta.uuid,
        titulo: pergunta.titulo,
        descricao: pergunta.descricao,
        imagem: "",
        tipo: pergunta.tipo,
        opcoes: pergunta.opcoes,
        categoria: pergunta.categoria,
        subcategoria: pergunta.subcategoria,
        obrigatoriedade: pergunta.obrigatoriedade,
        limite_de_opcoes: pergunta.limite_de_opcoes,
      });
    },
    createdEstrutraPerguntas() {
      const perguntasAbertas = [];
      this.perguntas = [];
      let perguntasBeneficios = [];
      let perguntasFavorabilidade = [];
      let perguntasMotivacional = [];
      let perguntasDesmotivacional = [];

      Object.keys(this.perguntasFormulario).map((item) => {
        if (item == "FE") {
          this.perguntasFormulario[item].map((pergunta) => {
            if (pergunta.tipo != "text") {
              this.addEstruturaPergunta(pergunta);
            } else {
              perguntasAbertas.push(pergunta);
            }
          });
        } else {
          Object.keys(this.perguntasFormulario[item]).map((subcategorias) => {
            this.perguntasFormulario[item][subcategorias].map((perguntas) => {
              switch (subcategorias) {
                case "FAV":
                  perguntasFavorabilidade.push(perguntas);
                  break;
                case "BEN":
                  perguntasBeneficios.push(perguntas);
                  break;
                case "MOT":
                  perguntasMotivacional.push(perguntas);
                  break;
                case "DES":
                  perguntasDesmotivacional.push(perguntas);
                  break;
              }
            });
          });
        }
        if (perguntasFavorabilidade.length > 0) {
          perguntasFavorabilidade.map((item) => {
            this.addEstruturaPergunta(item);
          });
        }
        if (perguntasBeneficios.length > 0) {
          perguntasBeneficios.map((item) => {
            this.addEstruturaPergunta(item);
          });
        }
        if (perguntasMotivacional.length > 0) {
          perguntasMotivacional.map((item) => {
            this.addEstruturaPergunta(item);
          });
        }
        if (perguntasDesmotivacional.length > 0) {
          perguntasDesmotivacional.map((item) => {
            this.addEstruturaPergunta(item);
          });
        }
      });

      if (perguntasAbertas && perguntasAbertas.length > 0) {
        perguntasAbertas.map((el) => {
          this.perguntas.push(el);
        });
      }
      this.ultimaResposta = this.respostas[this.respostas.length - 1];
      this.questionIndex =
        this.ultimaResposta &&
        this.ultimaResposta.indexPergunta !== null &&
        this.respostas.length > 0
          ? this.perguntas.findIndex(
              (pergunta) => pergunta.uuid === this.ultimaResposta.uuid_pergunta
            )
          : 0;
    },
    /**
     * Verificar a obrigatoriedade da pergunta
     * Verificar se a mesma já possui resposta no objeto de resposta
     * @param {*} pergunta
     */
    verificarObrigatoriedade(pergunta) {
      let resposta;
      if (pergunta.obrigatoriedade == 1) {
        if (this.respostas === null && this.respostas.length === 0) {
          return true;
        } else {
          resposta = this.respostas.filter(
            (el, i) => el.uuid_pergunta == pergunta.uuid
          );
          if (resposta.length > 0) {
            if (
              pergunta.tipo == "radio" &&
              resposta[0].uuid_escala_itens != ""
            ) {
              return false;
            } else if (pergunta.tipo == "radio" && resposta[0].opcao != "") {
              return false;
            } else if (
              pergunta.tipo == "checkbox" &&
              resposta[0].opcao.length > 0
            ) {
              return false;
            } else if (
              pergunta.tipo == "checkbox" &&
              resposta[0].descricao != null
            ) {
              return false;
            } else if (
              pergunta.tipo == "text" &&
              resposta[0].descricao != null
            ) {
              return false;
            } else {
              return true;
            }
          }
          return true;
        }
      }
    },
    anterior() {
      if (this.perguntas.length > 0) this.questionIndex--;
    },
  },
  created() {
    this.iniciandoQuiz = true;
    this.createdEstrutraPerguntas();
  },
  watch: {
    mensagemRetornoResposta() {
      setTimeout(() => {
        this.mensagemRetornoResposta = "";
      }, 3000);
    },
  },
  computed: {
    pesquisa: {
      get() {
        return this.$store.getters["pesquisas/getDadosPesquisaExterna"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setDadosPesquisaExterna", value);
      },
    },
    respostas: {
      get() {
        return this.$store.getters["pesquisas/getRespostas"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setRespostas", value);
      },
    },
    dialogFinalizado: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaFinalizadaDialogExterna"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaFinalizadaDialogExterna",
          value
        );
      },
    },
    formulario: {
      get() {
        return this.$store.getters["pesquisas/getHabilitarFormulario"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setHabilitarFormulario", value);
      },
    },
    code: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaCodigoExternaMessage"];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaCodigoExternaMessage",
          value
        );
      },
    },
    perguntasFormulario: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaPerguntas"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaExternaPerguntas", value);
      },
    },
    questionIndex: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaIndex"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaExternaIndex", value);
      },
    },
    ultimaResposta: {
      get() {
        return this.$store.getters["pesquisas/getPCOUltimaPerguntaRespondida"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPCOUltimaPerguntaRespondida", value);
      },
    },
    loadingEstruturaFormulario: {
      get() {
        return this.$store.getters[
          "pesquisas/getPCOLoadingEstruturaFormulario"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPCOLoadingEstruturaFormulario",
          value
        );
      },
    },
    componenteInicial: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteInicial"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteInicial",
          value
        );
      },
    },
    componenteFormulario: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteFormulario"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteFormulario",
          value
        );
      },
    },
    mensagemRetornoResposta: {
      get() {
        return this.$store.getters["pesquisas/getPcoMensagemRetorno"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPcoMensagemRetorno", value);
      },
    },
    /**
     * @description Desabilita o botão de finalizar caso a ultima pergunta não esteja seguindo a regra.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    disabledBtnFinalizar() {
      if (
        this.perguntas &&
        this.perguntas.length > 0 &&
        this.respostas &&
        this.respostas.length > 0
      ) {
        if (this.perguntas[this.questionIndex].obrigatoriedade == 0)
          return false;
        else if (
          this.perguntas[this.questionIndex].descricao.length == 0 ||
          this.perguntas[this.questionIndex].opcoes.length > 0
        ) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style lang="scss"></style>
